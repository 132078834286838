.cartpage-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.discount-offer__text > P {
  color: #276f86;
}

.cart-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: space-between;
}

.cart-item-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 12px;
  border-radius: 10px;
  background: var(--surface);
  margin-bottom: 10px;
  width: 100%;
  gap: 20px;
}

.cart-item-left {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.cart-item-image {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.cart-item-image > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.cart-product-description {
  display: flex;
  flex-direction: column;
}

.cart-product-description > p,
.region-level-div > p {
  margin-bottom: 0;
}

.region-level-div {
  display: flex;
  gap: 16px;
}

.be {
  position: relative;
}

.be > p {
  margin: 0;
}

.cart-product-description > .be > div.info-popup-wrapper {
  position: absolute;
  top: 0;
  right: 5px;
  margin: 0 !important;
}

.card-labels {
  display: flex;
  gap: 2px;
  flex-wrap: wrap;
}

.product-price {
  color: var(--normal-text);
  margin: 0;
  font-weight: 500;
  font-size: 1.2rem;
}

.cart-item-delete {
  color: var(--neutral-400);
  font-size: 1.2rem;
  cursor: pointer;
}

.delete-item {
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: spin infinite 5s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checkout-header {
  display: flex;
  flex-direction: column;
}

.checkout-header > h2 {
  margin-bottom: 10px;
  font-weight: 500;
}

.summary-div {
  display: flex;
  flex-direction: column;
  background: var(--surface);
}

.summary-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.cart-page-sidebar {
  width: 100%;
  margin-left: auto;
}

.order-summary-new {
  width: 100%;
  background: var(--surface);
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 10px 15px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
}

.item-summary-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--surface);
}

.summary-item-each {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 0;
  border-bottom: 1px solid hsla(200, 72%, 28%, 0.25);
}

.summary-item-each:first-child {
  border-top: 1px solid hsla(200, 72%, 28%, 0.25);
}

.summary-item-each > p {
  margin: 0;
}

.balance-info {
  color: var(--warning);
}

.cart-item-skeleton .ui.placeholder {
  max-width: 100%;
}

.filter-btns > button.ui.button {
  background: var(--surface);
  color: var(--normal-text) !important;
  border: 1px solid var(--border-color);
  margin-bottom: 0.4rem;
}

.filter-btns > button.ui.button:hover {
  opacity: 0.8;
}

.fields.discount-code-form {
  padding: 0;
  margin: 0em -0.5em 0em !important;
}

.discount-code .ui.input input[type="text"] {
  background: var(--surface-secondary) !important;
  color: var(--normal-text) !important;
}

.no-items-selected {
  width: 100%;
  text-align: center;
  padding: 10px 0;
  border-top: 1px solid hsla(200, 72%, 28%, 0.25);
  border-bottom: 1px solid hsla(200, 72%, 28%, 0.25);
}

.no-cart-items-segment {
  width: 100%;
  max-width: 770px !important;
  height: fit-content;
  background: var(--surface) !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid var(--border-color) !important;
}

.no-cart-items__icon {
  font-size: 3rem;
  color: var(--neutral-400);
}

.no-cart-items__text {
  font-size: 1.2rem;
  font-weight: 500;
  margin-top: 1.2rem;
}

.inline-flex {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}

.items-count {
  background: #1678c2;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 12px;
  color: #efeeee;
}

.payment-gateway-selection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}

.payment-gateway-selection .item {
  flex-grow: 1;
  height: 100%;
}

.payment-gateway-selection .item .header {
  display: flex;
  align-items: center;
}

.payment-gateway-selection > .ui.button {
  background: var(--surface);
  border: 1px solid var(--border-color);
}

.payment-gateway-selection > .ui.button.active {
  background: #eff7fd;
  border-color: #5c97ab;
}

.payment-gateway-selection > .ui.button.active.dark {
  background: #205885;
  border-color: #545d60;
}

.modal-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal-close {
  font-size: 18px;
  cursor: pointer;
}

@media (min-width: 500px) {
  .filter-buttons-separator {
    height: 40px;
  }
}

@media (min-width: 768px) {
  .cartpage-header {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .discount-offer__text {
    width: 358px;
  }

  .cart-container {
    flex-direction: row;
  }

  .cart-page-sidebar {
    width: 350px;
  }

  .cart-item-group {
    display: flex;
    flex-direction: column;
    gap: 6px;
  }

  .cart-item {
    justify-content: unset;
    gap: 20px;
    max-width: 378px;
  }

  .card-labels {
    width: 208px;
  }

  .filter-buttons-separator {
    height: 60px;
  }
}

@media (min-width: 800px) {
  .cart-item {
    max-width: 100%;
    padding: 10px 18px;
  }

  .discount-offer__text {
    width: 320px;
  }

  .cart-product-description {
    width: 168px;
  }

  .cart-page-sidebar {
    min-width: 320px;
  }

  .filter-buttons-separator {
    height: 40px;
  }

  .cart-item-delete {
    margin-left: 0.5rem;
  }
}

@media (min-width: 1220px) {
  .cart-item {
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 770px;
  }

  .cart-item-skeleton {
    width: 770px;
  }

  .discount-offer__text {
    width: 380px;
  }

  .cart-page-sidebar {
    min-width: 380px;
  }

  .cart-item-left {
    justify-content: unset;
    gap: 20px;
    width: fit-content;
  }
}
