.status-container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.status-container::after {
  background-color: #b0b0b0;
  content: "";
  position: absolute;
  left: calc(8px);
  width: 2px;
  height: 90%;
}

.status-item {
  display: flex;
  position: relative;
  margin-bottom: 12px;
}

.status-item-content {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 30px;
}
.status-item-content h4 {
  margin: 0px;
}

.status-item-content.dim {
  opacity: 0.6;
}

.status-item .circle-icon {
  position: absolute;
  border: 1px solid #a4a4a4;
  background-color: #a4a4a4;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  left: 0;
  top: 0;
  z-index: 100;
}

.status-item .circle-icon.completed {
  background-color: #5b9e08;
  border-color: #ffffff;
  color: #ffffff;
}
.status-item .circle-icon.active {
  color: #ffffff;
  border: 2px solid #5b9e08;
  background-color: #ffffff;
}
