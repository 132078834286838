.sidebar-menu-wrapper {
  position: sticky !important;
  top: 20px !important;
  width: 400px !important;
  min-height: 600px;
  height: 100%;
  max-height: 95vh;
  padding: 12px;
}

.sidebar-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 35px;
}

.sidebar-menu {
  margin: 30px 0;
}

.search-item {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.skin-search-inner {
  flex-grow: 1;
}

.sidebar .ui.secondary.menu .item {
  padding: 4px !important;
}

.account-tier-form {
  display: inline-flex;
  gap: 10px;
}
