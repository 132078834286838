@import url(rank.css);
@import url(sidebar.css);
body {
  overflow-y: scroll;
}

.ui.info.message p {
  color: #276f86;
}

.ui .error .message .content p {
  color: #9f3a38;
}

.nav-wrapper {
  border-radius: 0px !important;
  margin: 0px !important;
  padding: 0.7em 0 !important;
}

.nav {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 95%;
  max-width: 1500px;
  margin: 0 auto !important;
  border: none !important;
}

.table-password {
  display: flex;
  align-items: center;
  gap: 10px;
  max-width: 120px;
}

.table-password > .password-text {
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.icon-only-label {
  cursor: pointer;
}

.ui.label.icon-only-label > i.icon {
  margin: 0;
}

.marketplace-page {
  height: 100%;
}

.ui.search .prompt {
  border-radius: 0.28571429rem !important;
}

@media only screen and (max-width: 767px) {
  .ui.stackable.menu {
    flex-direction: row !important;
    align-items: center !important;
  }
  .ui.stackable.menu .right.menu,
  .ui.stackable.menu .right.item {
    margin-left: auto !important;
  }
}

@media (max-width: 1200px) {
  .lolaccshop-referal {
    padding-left: 1rem;
  }
}

.logo {
  margin-left: -12px;
}

.ui.menu .item:before {
  display: none !important;
}

.nav-logo-title {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.navbar-divider {
  border-left: 2px solid white;
  height: 30px;
}

.smurfskins-logo-title {
  height: 70px;
  width: auto;
}

.smurfskins-logo-title:hover {
  cursor: pointer;
}

.logo-title {
  font-size: 2rem;
  font-weight: 100;
}

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.lolaccshop-referal {
  margin-top: -2%;
}

.lolaccshop-referal:hover {
  cursor: pointer;
}

.footer {
  width: 95%;
  margin: 0 auto !important;
  max-width: 1500px;
}

.footer-wrapper {
  border-radius: 0px !important;
  margin: 0px !important;
}

.buy-account-page {
  height: 100%;
  width: 100%;
}

.buy-account-container {
  height: 100%;
  display: flex;
  gap: 20px;
  align-items: flex-start;
}

.product-card-group {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
  align-items: flex-start;
  gap: 20px;
}

.product-card.dark-mode {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.product-pagination {
  margin: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sidebar {
  z-index: 1;
  position: sticky;
  top: 0;
  padding-bottom: 3rem;
}

.info-popup-wrapper {
  margin: 4px;
}

.info-popup ul {
  margin: 0 -20px;
}

.info-popup-icon {
  opacity: 0.5 !important;
}

.custom-dropdown {
  flex-grow: 1;
}

.product-card {
  display: flex;
  min-width: 300px;
  margin: 0px !important;
}

.product-details {
  flex-grow: 1;
  background-color: var(--surface) !important;
}

.product-card-image-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 !important;
  max-width: 318px !important;
  overflow: hidden;
}

.product-card-icons-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  top: 0px;
  padding: 1em;
  gap: 5px;
  width: 100%;
}

.product-card-icons {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  flex-grow: 1;
}

.product-card-labels {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 60%;
}

.product-card-image {
  min-height: 300px;
  height: 100% !important;
  width: 100% !important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.product-card-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding-top: 0 !important;
  background-color: var(--surface) !important;
}

.product-card-buttons > button {
  margin: 0 !important;
  flex-grow: 1;
}

.product-header {
  display: flex;
  justify-content: space-between;
}

.price {
  font-size: large;
}

.product-description {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-description > .be {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.product-icon {
  font-size: 1.5em;
  height: 1.18em;
  width: 1em;
}

.your-purchases-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.your-purchases-tabs {
  display: flex;
  flex-direction: row;
}

.no-search-results {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 480px;
}

.user-profile {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 3em 10%;
  gap: 5em;
}

.user-profile-image {
  background-color: #ebebeb !important;
  width: 80px;
  height: 80px;
}

.user-profile-content {
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex-grow: 1;
  gap: 1em;
}

.my-account-segment {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  padding: 0 !important;
}

.my-account-grid {
  margin: 0 !important;
}

.your-purchases-wrapper {
  overflow-x: auto;
}

.information-container {
  padding: 3em 0em;
}

.reset-password-container {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
}

.cart-page-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 95%;
  height: 100%;
  padding: 3rem 10px;
  max-width: 1280px;
  margin: 0 auto;
  gap: 20px;
}

.cart-page-sidebar {
  display: flex;
  flex-flow: column;
  align-items: stretch;
  gap: 10px;
}

.cart-page-sidebar-row {
  display: flex;
  gap: 10px;
}

.cart-page-sidebar-info {
  flex-grow: 1;
}

.cart-item-options {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  padding: 10px 0px;
}

.cart-item > .product-card {
  flex-grow: 1;
}

.discount-code {
  flex-grow: 1 !important;
}

.search-items {
  margin-top: 5px;
}

.error-page {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.recaptcha {
  margin-bottom: 16px;
}

.redeem-coupon-form,
.transfer-balance-form,
.load-balance-form,
.change-password-form {
  max-width: 600px;
}

.content-container .segments.error {
  border-color: #dc3545 !important;
}

.trade-modal-actions {
  position: relative;
}

.trade-modal-stepper {
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.trade-modal-actions-back {
  position: absolute;
  left: 0%;
}

.trade-modal.dark.ui.modal > .header {
  background-color: var(--background);
  border-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.trade-modal.dark.ui.modal > .content {
  background-color: var(--surface);
  color: white;
}

.ui.dimmer .ui.loader.trade-modal-loader.light {
  color: black;
}

.ui.dimmer .ui.loader.trade-modal-loader.light:before {
  border-color: rgba(0, 0, 0, 0.1);
}

.ui.dimmer .ui.loader.trade-modal-loader.light:after {
  border-color: #767676 transparent transparent;
}

.trade-modal.ui.modal > .content > .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2em;
}

.trade-modal.ui.modal > .content > .description > .form {
  max-width: 300px;
}

.trade-modal.dark.ui.modal > .actions {
  background-color: var(--surface);
  border-color: rgba(255, 255, 255, 0.1);
  color: white;
}

.trade-modal.dark > .close.icon {
  color: white;
}

.ui.inverted.attached.segment {
  border-color: transparent !important;
}

.ui.attached.segment {
  border-radius: 0 0 0.28571429rem 0.28571429rem;
}

.your-purchases .item .header {
  opacity: 0.4;
}

.your-purchases .item .header:hover {
  opacity: 0.5;
}

.your-purchases .active.item .header {
  opacity: 1;
}

.account-type-radio {
  display: flex !important;
  flex-wrap: wrap;
}

.export-data-wrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
  gap: 15px;
}
.generate-file {
  width: 45%;
}
.generate-file-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.generate-file-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.export-data-table-wrapper {
  width: 55%;
  margin-top: 0 !important;
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}
.export-data-table {
  margin-top: 0 !important;
  border-radius: 0 0 0.28571429rem 0.28571429rem !important;
}
.export-data-table-message {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

@media (max-width: 1600px) {
  .export-data-wrapper {
    flex-direction: column;
  }
  .generate-file {
    width: 400px;
  }
  .export-data-table-wrapper {
    min-width: 450px;
    width: 100%;
  }
}

@media (max-width: 1200px) {
  .your-purchases-header {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 1rem;
  }
}

@media (max-width: 1000px) {
  .your-purchases-tabs {
    display: flex;
    flex-direction: column;
    a {
      align-self: start !important;
    }
  }
}

@media (max-width: 900px) {
  .cart-page-sidebar-row {
    flex-direction: column;
  }
  .trade-modal-stepper {
    display: none;
  }
}

@media (max-width: 500px) {
  .lolaccshop-referal-logo {
    width: 100%;
    height: auto;
  }
}

@media (max-width: 767px) {
  /* mobile */
  .active.item {
    border-color: transparent !important;
  }

  .ui.vertical.divider {
    display: none !important;
  }

  .user-profile {
    flex-direction: column;
  }

  .user-profile-content {
    padding: 0em;
  }

  .cart-item-options {
    flex-direction: column;
    align-items: flex-start;
  }

  .cart-item {
    align-items: center;
  }

  .nav {
    justify-content: flex-start;
    align-items: flex-start;
  }

  .nav-logo-title {
    flex-direction: column;
    align-items: flex-start;
  }

  .navbar-divider {
    display: none;
  }

  .logo {
    margin-left: -12px;
  }

  .trade-modal-actions {
    display: flex;
  }

  .trade-modal-actions-back {
    position: relative;
  }
}

@media (max-width: 800px) {
  .buy-account-container {
    flex-direction: column;
  }
  .sidebar {
    position: relative;
  }
}

@media (max-width: 420px) {
  .product-card {
    min-width: 280px;
  }
}
