.iron {
  background-color: #3c2d29 !important;
  color: #fff !important;
}
.bronze {
  background-color: #a5673f !important;
  color: #fff !important;
}
.silver {
  background-color: #c0c0c0 !important;
  color: #fff !important;
}
.gold {
  background-color: #eaae00 !important;
  color: #fff !important;
}
.platinum {
  background-color: #52a596 !important;
  color: #fff !important;
}
.emerald {
  background-color: #3ab373 !important;
  color: #fff !important;
}
.diamond {
  background-color: #008ad8 !important;
  color: #fff !important;
}
.master {
  background-color: #d265f9 !important;
  color: #fff !important;
}
.grandmaster {
  background-color: #bc3928 !important;
  color: #fff !important;
}
.challenger {
  background-color: #78ccd8 !important;
  color: #fff !important;
}
.handleveled {
  background-color: #1678c2 !important;
  color: #fff !important;
}
