.homepage-container {
  width: 90%;
  margin: 130px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 130px;
}

.vision-container {
  padding: 12px;
  background: url("../../assets/vision-bg.png") no-repeat;
  background-size: cover;
  background-position: center;
  height: 320px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}

.container-title {
  font-size: 28px;
  font-weight: 600;
  position: relative;
}

.container-title::after {
  content: "";
  position: absolute;
  bottom: -10px;
  left: 0;
  width: 60px;
  height: 3px;
  background: #ef4b6c;
}

.vision-title {
  color: #fff;
}

.vision-details {
  font-size: 18px;
  font-weight: 400;
  margin-top: 12px;
  max-width: 35ch;
  color: #d1d5db;
}

.service-provided-wrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 100%;
}

.services-provided-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.service-provided-group {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.service-provided-icon {
  border-radius: 10px;
  /* background: #072c3f;
  color: #14597b; */
  padding: 12px;
}

.service-provided-title {
  margin: 12px 0;
}

.service-provided__image {
  display: none;
}

.service-provided__image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.review-container-wrapper {
  /* background: #052535; */
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.reviews-carousel {
  padding: 12px;
  border-radius: 10px;
}

.review-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.review-item {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin: 16px 0;
  padding-bottom: 8px;
}

.review-item > h2 {
  margin: 4px 0 4px 0;
  font-weight: 500;
}

.service-details {
  font-size: 18px;
  font-weight: 500;
}

.join-discord {
  display: inline-flex;
  align-items: center;
  gap: 18px;
}

.join-discord > p {
  font-size: 12px;
  /* color: #bcbaba; */
  max-width: 230px;
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.faq-accordion {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item {
  /* background: #052535; */
  padding: 10px 14px;
  border-radius: 10px;
}

.faq-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rotate {
  transform: rotate(180deg);
  transition: 0.3s ease-in-out;
}

.faq-title > p,
svg {
  color: var(--text-secondary);
  font-size: 18px;
  font-weight: 500;
  margin: unset;
}

.faq-content {
  font-size: 16px;
  transition: all 0.6s ease-in-out;
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s cubic-bezier(0, 0.5, 0, 1);
}

.faq-content.opened {
  max-height: 500px;
  transition: max-height 0.4s ease-in-out;
}

.faq-container__image {
  display: none;
}

.join-discord-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
}

.discord-links {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.discord-links__text {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.discord-links__text {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: -webkit-fill-available;
}

.discord-links__text > p {
  font-size: 18px;
  font-weight: 500;
  margin: unset;
}

.social-links {
  display: flex;
  gap: 12px;
}

.social-links > a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
}

.social-link-icon-dark {
  background: #072c3f;
}

.social-link-icon-light {
  background: #c5c5c5;
}

.social-icon-dark {
  color: #14597b;
  font-size: 24px;
}

.social-icon-light {
  color: #f8f8f9;
  font-size: 24px;
}

.social-link-icon-dark:hover > .social-icon-dark {
  color: #097eb8;
}

.social-link-icon-light:hover > .social-icon-light {
  color: #e9e9e9;
}

@media (min-width: 768px) {
  .homepage-container {
    width: 85%;
  }
  .vision-container {
    padding: 12px 32px;
  }
  .reviews-carousel {
    padding: 12px 32px;
  }
  .review-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .join-discord-container {
    flex-direction: row;
    justify-content: space-between;
  }
  .discord-links__text > p {
    max-width: 50ch;
  }
  .social-links-div > h3 {
    font-size: 20px;
  }
}

@media (min-width: 500px) {
  .service-provided-group {
    display: grid;
    grid-template-columns: auto auto;
    gap: 30px;
  }
}

@media (min-width: 1080px) {
  .service-provided-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
  .service-provided-group {
    grid-template-columns: 35ch 35ch;
  }
  .service-provided__image {
    display: block;
    width: 450px;
    height: initial;
    border-radius: 10px;
    overflow: hidden;
  }
  .faq-group-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 500px;
  }
  .faq-item {
    width: 450px;
  }
  .faq-container__image {
    display: block;
    width: 400px;
    height: 500px;
    border-radius: 10px;
    overflow: hidden;
  }
  .faq-container__image > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

@media (min-width: 1220px) {
  .homepage-container {
    width: 1200px;
  }
  .vision-container {
    padding: 12px 32px;
  }
  .faq-item {
    width: 600px;
  }
  .faq-container__image {
    width: 450px;
    height: 475px;
  }
}
