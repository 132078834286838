.ham-menu {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1rem;
  width: 100%;
  padding-bottom: 1rem;
}

.mobile-nav-cart-icon {
  display: inline-flex;
  align-items: center;
}

.ui.teal.mini.circular.floating.label.mobile-nav-cart-label, .ui.teal.mini.floating.label.mobile-marketplace-label {
  position: unset !important;
  margin-left: 10px !important;
}
